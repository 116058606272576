import { useEffect, useState } from 'react'

export default (key, defaultValue) => {
  let currentValue = defaultValue

  try {
    currentValue = sessionStorage.getItem(key)
  }
  catch {

  }

  if (currentValue === null) {
    currentValue = defaultValue
  }

  const [value, setValue] = useState(currentValue)

  const setSessionStorageFromValue = () => {
    try {
      sessionStorage.setItem(key, value)
    }
    catch {

    }
  }
 
  useEffect(setSessionStorageFromValue, [ value ])
 
  return [value, setValue]
}