import React from 'react'

import Layout from '../../components/layout'
import Wizard from '../../components/wizard'
import SessionStorageInput from '../../components/sessionStorageInput'

import useStateWithSessionStorage from '../../helpers/useStateWithSessionStorage'

const PrototypePage = () => {
  const [ currentChecked, setCurrentChecked ] = useStateWithSessionStorage('primaryNodeName', 'Insight')

  const radioProps = (id) => ({
    checked: currentChecked === id,
    className: 'form-check-input',
    id,
    name: 'customize',
    onChange: () => setCurrentChecked(id),
    type: 'radio',
    value: id,
  })

  return (
    <Layout>
      <Wizard title="Customize" backTo="/onboarding-1/custom-node-type" nextTo="/onboarding-1/tag-types">
        <div className="row m-0">
          <div className="col p-0">
            <p>What do you call the container that you use to store multiple objects and organize your work in your research repository?</p>
            <form>
              <div className="form-check my-2">
                <input {...radioProps('Project')} />
                <label className="form-check-label font-weight-bold" htmlFor="Project">Project</label>
              </div>
              <div className="form-check my-2">
                <input {...radioProps('Folder')} />
                <label className="form-check-label font-weight-bold" htmlFor="Folder">Folder</label>
              </div>
              <div className="form-check my-2">
                <div>
                <input {...radioProps('Custom')} />
                <label className="form-check-label font-weight-bold" htmlFor="Custom">Custom</label>
                </div>
                <SessionStorageInput className="form-control mt-2" name="primaryNodeNameCustom" />
              </div>
              <div className="form-check my-2">
                <input {...radioProps('Decide later')} />
                <label className="form-check-label font-weight-bold text-secondary" htmlFor="Decide later">Decide later</label>
              </div>
            </form>
          </div>
        </div>
      </Wizard>
    </Layout>
  )
}

export default PrototypePage
