import React from 'react'
import useStateWithSessionStorage from '../helpers/useStateWithSessionStorage'

const SessionStorageInput = (inputProps) => {
  const [ value, setValue ] = useStateWithSessionStorage(inputProps.name, '')

  return (
    <input
      onChange={e => setValue(e.target.value)}
      value={value}
      {...inputProps}
    />
  )
}

export default SessionStorageInput
